import React from 'react'
import { Container, Row } from 'reactstrap'

import Layout from '../../layout/Layout'
import ApartmentsRoll from '../../components/ApartmentsRoll'

export const ApartmentssIndexPage = () => (
  <Layout>
    <Container>
      <Row>
        <div className='text-center mt-5 mb-5' style={{
          width: '100%'
        }}>
          <h1>Apartments</h1>
        </div>
        <div className='mb-5' style={{
          width: '100%'
        }}>
          <ApartmentsRoll />
        </div>
      </Row>
    </Container>
  </Layout>
)

export default ApartmentssIndexPage

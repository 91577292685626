import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import { Container, Card, CardHeader, CardBody, Badge } from "reactstrap";
import { FaUserFriends } from "react-icons/fa";
import Img from "gatsby-image";
import { IoIosBed, IoIosWifi, IoIosRestaurant } from "react-icons/io";
import Image from "./Image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
class ApartmentsRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: rooms } = data.allMarkdownRemark;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 700 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1,
      },
    };
    return (
      <Container fluid>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              .card-link {
                color: inherit;
                height: 100%;
                width: 100%;
              }
              .card{
                height: 100%;
              }
              .react-multi-carousel-list{
                padding-bottom: 3em;
              }  
              .card-link:hover {
                color: inherit;
                text - decoration: none;
              }`,
          }}
        />
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          arrows={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={6000}
          keyBoardControl={true}
          customTransition="all 1.5s"
          transitionDuration={1000}
        >
          {rooms &&
            rooms.map(({ node: room }) => (
              <Link to={room.fields.slug} className="card-link">
                <Card>
                  <CardHeader>
                    {room.frontmatter.gallery ? (
                      <Image
                        imageInfo={{
                          image: room.frontmatter.gallery.image1,
                          alt: "",
                        }}
                      />
                    ) : null}
                  </CardHeader>
                  <CardBody>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "1.3rem",
                      }}
                    >
                      {room.frontmatter.title}
                    </p>
                    <address>
                      <p>
                        {room.frontmatter.address.street}
                        {room.frontmatter.address.street &&
                          room.frontmatter.address.city
                          ? ", "
                          : null}
                        {room.frontmatter.address.city}
                      </p>
                    </address>
                    <div style={{ display: "flex" }}>
                      {room.frontmatter.info.guests ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0.5em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {room.frontmatter.info.icon1 ?
                            <Img
                              fixed={
                                room.frontmatter.info.icon1.childImageSharp.fixed
                              }
                            />
                            : null}
                          <span
                            style={{
                              marginTop: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            {room.frontmatter.info.guests
                              ? room.frontmatter.info.guests + " guests"
                              : 0}{" "}
                          </span>
                        </div>
                      ) : null}
                      {room.frontmatter.info.beds ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0.5em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {room.frontmatter.info.icon2 ?
                            <Img
                              fixed={
                                room.frontmatter.info.icon2.childImageSharp.fixed
                              }
                            />
                            : null}
                          <span
                            style={{
                              marginTop: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            {room.frontmatter.info.beds
                              ? room.frontmatter.info.beds + " beds"
                              : 0}{" "}
                          </span>
                        </div>
                      ) : null}
                      {room.frontmatter.info.wifi ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0.5em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {room.frontmatter.info.icon3 ?
                            <Img
                              fixed={
                                room.frontmatter.info.icon3.childImageSharp.fixed
                              }
                            />
                            : null}
                          <span
                            style={{
                              marginTop: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            Wifi
                          </span>
                        </div>
                      ) : null}
                      {room.frontmatter.info.kitchen ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0.5em",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {room.frontmatter.info.icon4 ?
                            <Img
                              fixed={
                                room.frontmatter.info.icon4.childImageSharp.fixed
                              }
                            />
                            : null}
                          <span
                            style={{
                              marginTop: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                            }}
                          >
                            Kitchen
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Link>
            ))}
        </Carousel>
      </Container>
    );
  }
}

ApartmentsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query ApartmentsRollQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "apartment" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                title
                description
                gallery {
                  image1 {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  image2 {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  image3 {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  image4 {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  image5 {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                address {
                  street
                  city
                }
                info {
                  guests
                  beds
                  wifi
                  kitchen
                  icon1 {
                    childImageSharp {
                      fixed(width: 18, height: 18) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  icon2 {
                    childImageSharp {
                      fixed(width: 18, height: 18) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  icon3 {
                    childImageSharp {
                      fixed(width: 18, height: 18) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  icon4 {
                    childImageSharp {
                      fixed(width: 18, height: 18) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ApartmentsRoll data={data} count={count} />}
  />
);
